.search-results {
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  grid-area: list;

  @media only screen and (max-width: $bp-small) {
    position: absolute;
    top: 20rem;
    z-index: 10;
    background-color: #fff;

    visibility: hidden;
    opacity: 0;
    transition: all 0.5s 0.2s;
    box-shadow: 0 4rem 6rem rgba(0, 0, 0, 0.25);

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }


  //&:hover,
  //.btn--sidebar:hover + & {
  //  visibility: visible;
  //  opacity: 1;
  //}


}

.results {
  list-style: none;
  margin-bottom: 2rem;
}

.pagination {
  margin-top: auto;
  padding: 0 3.5rem;

  &::after {
    content: '';
    display: table;
    clear: both;
  }

  &__btn {
    &--prev {
      float: left;
    }

    &--next {
      float: right;
    }
  }
}

.copyright {
  color: $color-grey-dark-2;
  font-size: 1.2rem;
  padding: 0 3.5rem;
  margin-top: 4rem;

  .twitter-link:link,
  .twitter-link:visited {
    color: $color-grey-dark-2;
  }
}

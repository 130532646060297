.header {
  grid-area: head;
  background-color: $color-grey-light-1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__logo {
    margin-left: 4rem;
    margin-right: 4rem;
    height: 4.6rem;
    display: block;

    @media only screen and (max-width: $bp-small) {
      margin-left: 8rem;
    }
  }

  @media only screen and (max-width: $bp-small) {

    display: grid;
    grid-template-columns: fit-content(100%) 20rem;
    grid-auto-rows: auto;
  }
}

.search {
  background-color: #fff;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  padding-left: 3rem;
  transition: all 0.3s;
  justify-self: end;
  justify-content: end;

  &:focus-within {
    transform: translateY(-2px);
    box-shadow: 0 0.7rem 3rem rgba($color-grey-dark-1, 0.08);
  }

  @media only screen and (max-width: $bp-small) {
    margin-right: 2rem;
    transform: unset;
    overflow: visible;
    &:focus-within {
      transform: unset;
    }

  }

  &__field {
    border: none;
    background: none;
    font-family: inherit;
    color: inherit;
    font-size: 1.7rem;
    width: 30rem;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $color-grey-light-3;
    }


    @media only screen and (max-width: $bp-medium) {
      width: 100%;

      &::placeholder {
        //color: white;
      }
    }

    @media only screen and (max-width: $bp-small) {
      overflow: clip;
      transition: width 1s;
      width: 10rem;

      &:focus-visible {
        width: 55vw;
      }

    }

  }

  &__btn {
    font-weight: 600;
    font-family: inherit;

    @media only screen and (max-width: $bp-small) {
      span {
        display: none;

      }
      .search__icon {
        margin-right: 0;
      }
    }
  }
}

.nav {
  align-self: stretch;
  margin-right: 2.5rem;

  &__list {
    list-style: none;
    display: flex;
    height: 100%;
  }

  &__item {
    position: relative;
  }

  &__btn {
    height: 100%;
    font-family: inherit;
    color: inherit;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0 1.5rem;
    transition: all 0.3s;

    display: flex;
    align-items: center;

    svg {
      height: 2.4rem;
      width: 2.4rem;
      fill: $color-primary;
      margin-right: 0.7rem;
      transform: translateY(-1px);
    }

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: $color-grey-light-2;
    }
  }

  @media only screen and (max-width: $bp-small) {
    grid-column: 1/-1;
    justify-self: center;
  }
}

.bookmarks {
  padding: 1rem 0;
  position: absolute;
  // right: 0;
  right: -2.5rem;
  z-index: 20;
  width: 40rem;
  background-color: #fff;
  box-shadow: 0 0.8rem 5rem 2rem rgba($color-grey-dark-1, 0.1);

  visibility: hidden;
  opacity: 0;
  transition: all 0.5s 0.2s;

  &__list {
    list-style: none;
  }

  &__field {
    cursor: pointer;
    padding: 0 4rem;

    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.3s;

    &:hover {
      background-color: $color-grey-light-2;
    }
  }

  &:hover,
  .nav__btn--bookmarks:hover + & {
    visibility: visible;
    opacity: 1;
  }
}

//*:focus-visible {
//  border: 2px solid red;
//
//}